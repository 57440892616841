.detail-gift-parent{ width: 94%; margin: auto; display: flex; align-items:center; height:88vh }
.flexbasis30{
    flex-basis: 30%;
}
.detail-gift-view-cart-btn{ flex-basis: 49%; display: flex; flex-direction: column }
.detail-gift-view-cart-btn-child{width:100%;display:flex;justify-content:flex-end}
.detail-gift-dialog{
    width:50vw
}
.detail-gift-dialog-body{
     display: flex; flex-wrap: wrap 
}

.detail-gift-dialog-footer{ display: flex; justify-content: flex-end }
.cart-screen-footer-parent{
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 2%;
    float: right;
    margin-right: 1%;
}
.cart-screen-footer-child-1{
    display:flex;
    justify-content:space-between;
    border:1px solid #e4e4e4;
    width: 50%;
    padding:2%;
    border-radius:5px 
}

.cart-screen-footer-child-2{ width: 50%;margin-top: 2% }
.proceed-to-checkout{ width: 90%; margin: auto }

.proceed-to-checkout-parent{
   display: flex;
}
.gift-header{
  justify-content: space-between;
  align-items: center;
}
.proceed-to-checkout-fields{
    display: flex;
    justify-content: space-between;
}
.flexBasis45{
    flex-basis: 45%;
}
@media screen and (max-width: 900px) {
    .proceed-to-checkout-parent{
        flex-direction: column;
    }
}
@media screen and (max-width: 750px) {
   
    .flexbasis30{
        flex-basis: 0;
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .detail-gift-parent{
        flex-direction: column;
        justify-content: center;
    }
.detail-gift-view-cart-btn{
    padding-left: 2%;
    padding-right: 2%;
}
.text-center-heading{
    text-align: center;
}
.gift-text-center-heading{
    display: inline-block;
    text-align: center;
}
.detail-gift-dialog{
    padding-left: 2%;
    padding-right: 2%;
}
.detail-gift-dialog-body,.detail-gift-dialog-footer{
    justify-content: center;
}
.cart-screen-footer-child-1,.cart-screen-footer-child-2{ width: 70%;}
.cart-screen-footer-parent{
    align-items: center;
}

}

@media screen and (max-width: 500px) {
   
      .cart-screen-footer-child-1,.cart-screen-footer-child-2{ width: 90%;}
      .gift-header{
          flex-direction: column;
      }
    .flexBasis45{
        flex-basis: 90%;
    }
   
 .proceed-to-checkout-fields{
    flex-direction: column;
 }
    .flexbasis30{
        padding-top: 8%;
    }
}
@media screen and (max-width: 400px) {
    .flexbasis30{
        padding-top: 12%;
    }
}
@media screen and (max-width: 355px) {
    .flexbasis30{
        padding-top: 16%;
    }
}



.p-dataview .p-dataview-content {
    width: 65%  !important;
    margin-left: 16% !important;
    justify-content: center !important;

}



@media screen and (min-width: 1700px) {
    .p-dataview .p-dataview-content
    {
        width: 52%  !important;
        margin-left: 24% !important;
        justify-content: center !important;
    }
}





.grid-nogutter {
    justify-content: center !important;
}



.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
    border-top-right-radius: 40px;
    background-color: #0354CC;

}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
    border-bottom-right-radius: 40px;
    background-color: #0354CC;

}

.p-fluid .p-inputnumber .p-inputnumber-input {
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
}


.p-dataview .p-dataview-header {
    margin-top: -4%;
}


@media screen and (max-width: 1537px) {
    .p-dataview .p-dataview-header {
        margin-top: -4%;
    }
}


.pgift-inputgroup {
    margin-right: 43%;
    margin-top: 3%;
}


@media screen and (max-width: 1920px) {
    .pgift-inputgroup {
        margin-right: 45%;
    }
}

@media screen and (max-width: 1537px) {
    .pgift-inputgroup {
        margin-right: 43%;
    }
}


@media screen and (max-width: 1229px) {
    .pgift-inputgroup {
        margin-right: 42%;
    }
}


@media screen and (min-width: 1538px) and (max-width: 1746px) {
    .pgift-inputgroup {
        margin-right: 44%;
    }
}

@media screen and (max-width: 1025px) {
    .pgift-inputgroup {
        margin-right: 40%;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1281px) {
    .pgift-inputgroup {
        margin-right: 42%;
    }
}

@media screen and (max-width: 1098px) {
    .pgift-inputgroup {
        margin-right: 41%;
    }
}


@media screen and (max-width: 961px) {
    .pgift-inputgroup {
        margin-right: 39%;
    }
}


@media screen and (min-width: 1925px) and (max-width: 2135px) {
    .pgift-inputgroup {
        margin-right: 45%;
    }
}


@media screen and (min-width: 2136px) and (max-width: 2403px) {
    .pgift-inputgroup {
        margin-right: 45.5%;
    }
}


@media screen and (min-width: 2403px) and (max-width: 2565px) {
    .pgift-inputgroup {
        margin-right: 46%;
    }
}


.card.cursor-pointer .p-datatable .p-datatable-thead > tr > th {
    background-color: #F1F1F1;
    font-weight: 1000;
}

@keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}