/* Common styles for both sections */
/* * {
  margin: 0;
  padding: 0;
} */
.selector-language-parent{display:flex;justify-content:flex-end;padding-top:10px;padding-right: 10px;}
.selector-dropdown-width{
flex-basis: 25%;
}
.signup-dropdown-backgroundColor{background:none}
.login-parent-div {
  height: auto;
  display: flex;
  width: 96%;
  margin: auto;
  align-items: center;
  padding-top: 1%;
  /* padding-bottom: 2%; */

}

.auth-parent {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-parent {
  width: 60%;
  margin: auto;
}

.text-center {
  text-align: center;
}

.image-indicate .p-image-preview-indicator{
  width: auto;
    height: auto;
    padding: 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
}

.input-width,
#password input,
#confirm_password input {
  width: 100%;
}

.input-border-radius,
#password input,
#confirm_password input {
  border-radius: 7px;
}

.register-parent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.register-input {
  display: flex;
  flex-direction: column;
}

.margin-top {
  margin-top: 7px;
}



.flex-basis-50 {
  flex-basis: 50%;
}

.logo-login-parent {
  width: 200px;
  /* Added 'px' to specify the width in pixels */
  margin: 0;
}

.logo-store-parent {
  height: 106;
  width: 200px;
  /* Added 'px' to specify the width in pixels */
  margin: 0;
}

.resetpassword-verify-email-p {
  color: black;
  font-weight: "600"
}

.login-partOne-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.width-complete-100 {
  width: 100%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.login-heading {
  padding-bottom: 2%;
  margin: 0;
}

.store-heading {
  margin: 0;
}

.login-para {
  width: 55%;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  color: #667085;
  padding-bottom: 2%;
  margin: 0;
}

.login-form-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-input-parent-div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.login-remember-div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-bottom: 2%;
}

.login-radio-parent {
  display: flex;
  align-items: center;
}

.width-fifty {
  width: 50%;
}

.login-submit-button {
  width: 100%;
  border-radius: 50px;
}

.navigate-parent {
  margin: 0;
  padding-top: 2%;
}


.submit-btn-div {
  display: flex;
  justify-content: center;
}

.navigate-auth-option-btn {
  display: flex;
  align-items: center;
}



.p-timeline-event-opposite {
  padding: 0 !important;
}





/* forgot screen css */
.reset-password-parent {
  display: flex;
  height: 100vh;
}

.reset-password-partOne {
  flex-basis: 33%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFB;
}

.align-self-logo-resetPassword {
  /* align-self: center; */
  padding-left: 10%;
}

.logo-forgotpassword-parent {
  display: flex;
  width: 200px;
  margin: 0;
  justify-content: center;
}

.timeline-parent {
  padding: 0 7%;
  padding-top: 15%;
}

.timeline-content-parent {
  display: flex;
  flex-direction: column;
}

.timeline-status {
  font-size: 14px;
  font-weight: 600;
}

.timeline-subtitle {
  font-size: 9px;
  color: #475467;
}

.reset-password-partTwo {
  flex-basis: 67%;
  
}

.reset-form-align-center {
  width: 50%;
  margin: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.profile-password-partTwo {
  flex-basis: 67%;
}
.profile-password-partTwo-child{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.profile-form-align-center {
  width: 60%;
}

.circleStyle {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
}

.forgotpassword-heading {
  padding: 2% 0;
  margin: 0;
}

.forgotpassword-para {
  width: 85%;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  color: #667085;
  padding-bottom: 2%;
  margin: 0;
}

.forgotpassword-input-parent-div {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.forgotpassword-submit-parent-div {
  width: 85%;
}

.confirmPassword-submit-parent-div {
  width: 85%;
  margin: 10px 0;
}

.reset-logo-img-width {
  width: 60%;
}

.navigate-back-reset-parent {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.reset-radio-parent {
  display: flex;
  align-self: flex-start;
  padding: 0 7.5%;
}

.navigate-back-reset-text-padding {
  padding-left: 15px;
  border: none;
  background: transparent;
}

.reset-password-marginBottom {
  margin: 10px 0;
}

.home-password-parent {
  background-color: #f7f7f7;
}

.profile-form-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-input-parent-div {
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
}

.profile-input-width {
  width: 100%;
}

.profile-submit-parent-div {
  width: 100%;
  margin: 10px 0;
}

.profile-password-partTwo .p-card .p-card-content {
  padding: 0 !important;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  object-fit: contain;
}
.signup-padding-top{
  padding-top: 5%;
}
@media screen and (max-width: 1100px) {
  .flex-basis-60 {
    flex-basis: 53%;
  }

  .flex-basis-40 {
    flex-basis: 47%;
  }

  .login-input-parent-div,
  .login-remember-div,
  .width-fifty,
  .login-para {
    width: 80%;
  }
  .reset-form-align-center {
    width: 80%;
    margin: auto;
  }

}

  @media screen and (max-width: 750px) {
    .flex-basis-60 {
      flex-basis: 100%;
    }
    .signup-dropdown-backgroundColor{background:#F9FAFB}

    .flex-basis-40 {
      flex-basis: 0
    }
    .signup-padding-top{
      padding-top: 2%;
    }
    
    .reset-password-parent{
      flex-direction: column;
    }
    
    .align-self-logo-resetPassword {
      align-self: center;
      padding:0;
    }
    .timeline-parent{
      padding: 0 3%;

      padding-top: 2%;
      
    }
    .reset-password-partTwo {
      flex-basis: 100%;
    }
    .reset-form-align-center {
      width: 100%;
      margin: auto;
    }
    .selector-dropdown-width{
      flex-basis: 40%;
    }
  }
  @media screen and (max-width: 500px) {
    .selector-dropdown-width{
      flex-basis: 80%;
    }
    .selector-language-parent{
      justify-content: center;
      padding-right: 0;
    }
    .login-input-parent-div,
    .login-remember-div,
    .width-fifty,
    .login-para {
      width: 90%;
    }
    /* .reset-password-partOne {
     padding-top: 52px;
     } */
     /* .login-partOne-parent {
padding-top: 52px;
     } */
  }