.container {
    position: fixed;
    width: 100%;
    height: 100%;
}

.selectedButton {
    background-color: #f9fafb;
    border: 2px solid black;
    color: dodgerblue;
    padding: 7px 15px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    border-color: #2196F3; 
}
.myButton {
    background-color: #f9fafb;
    border: 2px solid transparent;
    color: grey;
    padding: 7px 15px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
}

.myButton:hover {
    background-color: white;
    border-color: #2196F3; 
    color: dodgerblue;
    padding: 7px 15px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
}


.dashboard {
    display: flex;
    margin-top: 150px;
    gap: 20px;
    justify-content: center;
}



.dashboardCard {
    height: 13%;
    width: 17%;
    
}


.dashboardCard .p-card-body{
    height: 100% !important;
    width: 100% !important;
    padding-top: 12%;
    margin-top: -18%;
}


.dashboardCard .p-card-content{
    height: 100% !important;
    width: 100% !important;
}

.dashboardCard .p-card-content h1 {
    height: 100% !important;
    width: 100% !important;
    font-size: 16px;
    color: black;
    margin-top: -4%;
    margin-bottom: 10%;
}



.dashboardCard .p-card-content h2 {
    height: 100% !important;
    width: 100% !important;
    font-size: 13px;
    color: grey;
}

.dashboardCard .p-card-content p {
    height: 100% !important;
    width: 100% !important;
    font-size: 10px;
    color: grey;

}
.dashboardCard .p-card .p-card-body {
    text-align: center; 
    padding: 20px; 
    margin: 10px; 
}

.dashboardCardTitle .p-card-body .p-card-title {
    font-size: 15px !important;
    margin-top: 0px !important;
    color:grey !important;
}

#cardContent {
    margin-bottom: -20px !important;
    color: grey;
    font-size: 12px !important;
}

#cardValue {
    font-size: 20px;
    font-weight: bold;
    padding: 0px;
    margin-top: -18px;
    color: black;
}


.dashboardGraph {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3%;
}



.uniqueVisitorGraph {
    width: 98%;
    height: 370px;
}



#unique-visitor-label {
    font-size: 16px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Public Sans", sans-serif;
}


.uniqueVisitorChartFilterbutton {
    float: right;
    margin-top: -46px;
}

.uniqueVisitorChartCard .p-card-body {
    width: 100% !important; 
    height: 100% !important;
}


.uniqueVisitorChartCard .p-card-content {
    width: 100% !important;
    height: 100% !important;
}

.p-card.p-component.uniqueVisitorChartCard {
    width: 100% !important;
    /* height: 100% !important; */
}



.incomeOverviewGraph {
    width: 32%;
    height: 370px;
    margin-top: 35px;
}


.incomeOverviewChartCard .p-card-body {
    width: 100% !important; 
    height: 100% !important;
}


.incomeOverviewChartCard .p-card-content {
    width: 100% !important;
    height: 100% !important;
}

.p-card.p-component.incomeOverviewChartCard {
    width: 100% !important;
    height: 100% !important;
}




@media only screen and (min-width: 1900px) and (max-width: 1920px) and (min-height: 900px) and (max-height: 955px) {
.dashboardGraph {
    margin-top: 3%;
}

.uniqueVisitorGraph {
    width: 98%;
    height: 580px;
}

.incomeOverviewGraph {
    width: 32%;
    height: 580px;
    margin-top: 35px;
}

}


@media only screen and (min-width: 1440px) and (max-width: 1550px) and (max-height: 760px)  {
    .dashboardGraph {
        margin-top: 3%;
    } 
    .uniqueVisitorGraph {
        width: 98%;
        height: 424px;
    }
    
    .incomeOverviewGraph {
        width: 32%;
        height: 424px;
        margin-top: 35px;
    }
    
}



@media only screen and (min-width: 1700px) and (max-width: 1750px) and (min-height: 800px) and (max-height: 860px)  {
    .dashboardGraph {
        margin-top: 39px;
    } 
    .uniqueVisitorGraph {
        width: 98%;
        height: 466px;
    }
    
    .incomeOverviewGraph {
        width: 32%;
        height: 466px;
        margin-top: 35px;
    }
    
}

@media only screen and (min-width: 2045px) and (max-width: 2140px) and  (min-height: 950px) and (max-height: 1060px)  {
    .dashboardGraph {
        margin-top: 68px;
    } 
    .uniqueVisitorGraph {
        width: 98%;
        height: 600px;
    }
    
    .incomeOverviewGraph {
        width: 32%;
        height: 600px;
        margin-top: 35px;
    }
    
}



.transactionTable {
    width: 94.5%;
    margin-left: 3%;
    margin-top: 34px;
    justify-content: center;
    align-items: center;
    height: 100%;
}


#dashboardTitle {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Public Sans", sans-serif;
}



#valueTitle {
    margin: 0px;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4;
    color: inherit;
}


#dashboardCard {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(38, 38, 38) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    overflow: hidden !important;
    border: 1px solid rgb(230, 235, 241) !important;
    border-radius: 8px !important;
    box-shadow: inherit !important;
}

#dashboardCard .p-card-content {
    padding: 0px !important;
}



#titleCard {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57;
    font-family: "Public Sans", sans-serif;
    color: rgb(140, 140, 140);
}



.custom-filter {
    width: 150px; 
    height: 30px; 
}

.small-text input {
    font-size: 14px;
    font-family: "Public Sans", sans-serif;
}

.small-text {
    font-family: "Public Sans", sans-serif;
}


.tableToggleButton {
    float: right;
    margin-top: -34px;
}

.transactiontable {
    margin-top: 60px;
    margin-left: 21px;
}

.searchField {
margin-top: 65px;
margin-left: 23px;
}

.searchrow {
    display: flex;
    gap: 3rem !important;
    margin-top: 10px;
}

.input-text {
font-family: "Public Sans", sans-serif;
font-weight: bold;
font-size: 16px;
}

.input-box {
width: 18%;
}

.input-box-transaction {
    width: 24%;
}

.calender-box {
    width: 17%;
}

.input-search {
 width: 8%;
}
.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: #6366F1;
}

.gap-3 {
    gap: 3rem !important;
}


.p-datatable .p-sortable-column .p-sortable-column-icon {
    width: 60%;
    margin-top: -11%;
}


.my-row-class {
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
    &:hover {
        background-color: #ddd;
    }
}

.css-hz1bth-MuiDialog-container {
    width: 100%;
}


.highcharts-loading {
    opacity: 1!important;
  }
  .highcharts-loading-inner {
    display: block;
  }
  
  .highcharts-loading-inner,
  .highcharts-loading-inner:before,
  .highcharts-loading-inner:after {
    background: #dfdfdf;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .highcharts-loading-inner {
    color: #dfdfdf;
    text-indent: -9999em;
    margin: 0 auto;
    top: 50%!important;
    position: relative;
    font-size: 11px;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .highcharts-loading-inner:before,
  .highcharts-loading-inner:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .highcharts-loading-inner:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .highcharts-loading-inner:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  


  @media only screen and (min-width: 2559px) and (min-height: 1305px)   {

    #dashboardTitle {
        margin-top: 1%;
    }
    .dashboardGraph {
        margin-top: 39px;
    } 
    .uniqueVisitorGraph {
        width: 98%;
        height: 550px;
    }
    
    .incomeOverviewGraph {
        width: 32%;
        height: 466px;
        margin-top: 35px;
    }
    
}


.hide-table-header .p-datatable-thead {
    display: none;
}


.hide-table-header .p-datatable-row {
    border: none;
}

.hide-table-header .p-card .p-card-content {
    padding: 0; 
}

.tran-card.p-card .p-card-content {
    padding: 0 !important;
}

.tran-card.p-card .p-datatable .p-datatable-wrapper {
    overflow: hidden  !important;
}



#dashboardCard1 {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(38, 38, 38) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    overflow: hidden !important;
    border: 1px solid rgb(230, 235, 241) !important;
    border-radius: 8px !important;
    box-shadow: inherit !important;
    width: 100%;
    height: 100%;
    display: flex;
}


#dashboardCard1 .p-card-content {
    padding: 0px !important;
}


@media only screen and (max-width: 1537px)   {

#dashboardCard1 {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(38, 38, 38) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    overflow: hidden !important;
    border: 1px solid rgb(230, 235, 241) !important;
    border-radius: 8px !important;
    box-shadow: inherit !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#dashboardCard1 .p-card-content {
    padding: 0px !important;
}

}


.viewpartnerbutton {
    max-width: 81%;
    height: 10%;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px !important;
    margin-left: 8% !important;
}