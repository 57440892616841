.sidebar {
  flex: 0 0 15%;
  background: #333;
  color: #fff;
  overflow-y: auto;
  height: 100%;
}
.sidebar-parent{
  display: flex;height:100vh
}
.p-sidebar-left .p-sidebar-content,.p-sidebar-header{
  padding: 0 !important;  background: #333 !important;

}
.p-sidebar-close{
  color:floralwhite !important;
}
.p-card-title{
  font-size: 18px !important;
}
.sidebar-item {
  padding: 16px 12%;
  cursor: pointer;
  transition: background-color 0.3s;

}

.sidebar-item:hover {
  background-color: #555;
}

.selected {
  background-color: #555;
}

/* profile css */



.text-center {
  text-align: center;
}

.cartscreen-column-width {
  width: "20%"
}


.input-border-radius {
  border-radius: 7px;
}



.margin-top {
  margin-top: 7px;
}

.login-partOne-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}



.login-submit-button {
  width: 100%;
  border-radius: 50px;
}




.p-timeline-event-opposite {
  padding: 0 !important;
}



/* forgot screen css */
.reset-password-parent {
  display: flex;
  height: 100vh;
}
.profile-password-partOne {
  flex-basis: 33%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFB;
}
/* .reset-password-partOne {
  flex-basis: 25%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFB;
} */

.logo-login-parent {
  width: 200px;
  /* Added 'px' to specify the width in pixels */
  margin: 0;
}

.align-self-logo-resetPassword {
  /* align-self: center; */
  padding-left: 10%;
}



.profile-timeline-parent {
  padding: 0 7%;
  padding-top: 35%;
}

.timeline-content-parent {
  display: flex;
  flex-direction: column;
}

.timeline-status {
  font-size: 14px;
  font-weight: 600;
}

.timeline-subtitle {
  font-size: 9px;
  color: #475467;
}



.cursor-pointer {
  cursor: pointer;
}

.profile-password-partTwo {
  flex-basis: 75%;
 
}
.profile-password-partTwo-child{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 2%;
}
.update-profile-personal-data-parent{
  display: flex; justify-content: space-between;align-items: center; width: 100%
}
.profile-form-align-center {
  width: 100%;
  height: 371px;
  overflow-y: auto;
  margin-right: 4%;
  margin-top: 2%;
}

.circleStyle {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
}

.forgotpassword-heading {
  padding: 2% 0;
  margin: 0;
}

.home-password-parent {
  background-color: #f7f7f7;
}

.profile-form-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-input-parent-div {
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
}

.profile-input-width {
  width: 100%;
}

.profile-submit-parent-div {
  width: 100%;
  margin: 10px 0;
}

.input-width,
#old_password input,
#new_password input,
#confirm_password input {
  width: 100%;
}

.input-border-radius,
#old_password input,
#new_password input,
#confirm_password input {
  border-radius: 7px;
}

.profile-password-partTwo .p-card .p-card-content {
  padding: 0 !important;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  object-fit: contain;
}

.p-timeline-event-opposite {
  display: none !important;
}

.profile-password-partTwo .p-splitbutton-menubutton {
  display: none !important;
}
.flexBasis45{
  flex-basis:45%;
}
.profile-password-partTwo .p-splitbutton-defaultbutton {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

}
.tooltip {
  position: relative;
}

.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-style: italic; /* Apply italic styling */
}

.tooltip:hover::after {
  opacity: 1;
}

/* .profile-submit-parent-div .go-a{
        position: absolute !important ;
    top: 49px !important;
    right: 220px !important;
      } */
/* gift component */
.dashboard-dropdown-parent{display:flex;justify-content:space-between;height:50;align-items:center}
.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-dropdown-width{
  flex-basis: 20%;
}
.center-payment{
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-parent{
  flex-basis:19%;
  margin-top: 3%;
}
.retailer-card{
  flex-basis:25%;
  margin-top: 3%;
}

.logout-modal-width{
width: 30%;
}
.handle-sidebar-btn-position{
  position: fixed;
  top: 2%;
  left: 2%;
}
.payment-inputs-parent{align-items:center;justify-content:space-between}
.graph-parent{
  display: flex;
}
.grafh-flexBasis{
  flex-basis: 50%;
}
.grafh-flexBasis-chart{
  flex-basis: 100%;
}
.card-container {
  position: relative;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.card-title {
  color: #fff;
  font-weight: bold;
}
.dialog-flex{
flex-basis: 19%;margin-right: 1%;
margin-top: 2%;
}
@media screen and (max-width: 1300px) {
  .graph-parent{
    flex-direction: column;
  }
  .graph-pie-center{
        align-self: center;

  }
  .grafh-flexBasis{
    flex-basis: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .logout-modal-width{
    width: 50%;
    }
     .profile-timeline-parent{
      padding-top:45% ;
     }
}
@media screen and (max-width: 1000px) {
  
     .profile-timeline-parent{
      padding-top:65% ;
     }
     .dialog-flex{
      flex-basis: 32%;
      }
}
@media screen and (max-width: 835px) {
  .logout-modal-width{
    width: 70%;
    }
  .p-card-title{
    font-size: 15px !important;
  }

  .dashboard-dropdown-width{
    flex-basis: 40%;
  }}
@media screen and (max-width: 760px) {
  .card-parent,.retailer-card{
    flex-basis:45%;
 
  }
  
 
}
@media screen and (max-width: 750px) {
  
.profile-password-partOne{
  flex-basis: 18%;
}
.profile-password-partTwo {
  flex-basis: 82%;
 
}
.dialog-flex{
  flex-basis: 49%;
  }
  .profile-timeline-parent{
    padding: 0 3%;

    padding-top: 10%;
  }
 
}
@media screen and (max-width: 600px) {
  .profile-form-align-center{
    height: auto;
  }
  .card-parent,.retailer-card{
    flex-basis:90%;
 
  }
  .payment-inputs-parent{
display: block;
  }
  .update-profile-personal-data-parent{
    align-items: normal;
    flex-direction: column;
  }
  .profile-input-parent-div{
    flex-basis: 100%;
  }

  
}
@media screen and (max-width: 500px) {
  .profile-password-partOne{
    flex-basis: 23%;
  }
  .profile-password-partTwo {
    flex-basis: 77%;
   
  }
  .dialog-flex{
    flex-basis: 99%;
    margin-right: 0;
    }
  .profile-password-partTwo-child{
    padding-top: 10%;
  }
    .profile-timeline-parent{
      padding: 0 3%;
  
      padding-top: 21%;
    }
  .handle-sidebar-btn-position{
    top: 88px;
  }
  .logout-modal-width{
    width: 80%;
    }
    
  .dashboard-dropdown-parent{
    flex-direction: column;
    margin-bottom: 15px;
 
  }
  .dashboard-dropdown-width{
    flex-basis: 100%;
  }
 
}
@media screen and (max-width: 455px) {
  
  .profile-timeline-parent{
    padding: 0 3%;

    padding-top: 28%;
  }
}
@media screen and (max-width: 410px) {
  
  .profile-timeline-parent{
    padding: 0 3%;

    padding-top: 31%;
  }
}
@media screen and (max-width: 380px) {
  
  .profile-timeline-parent{
    padding: 0 3%;

    padding-top: 34%;
  }
}